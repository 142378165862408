import { useRef, useState } from "react"
import styled from "styled-components"

import { IconButton } from "@material-ui/core"

import { SecretPopover } from "src/components/ApiClients/ApiClientsTable"
import { ParadiseOverviewExpandable } from "src/components/Paradise/ParadiseOverviewExpandable"
import { ParadiseSettingStatic } from "src/components/Paradise/ParadiseSettings/ParadiseSettingStatic"
import { ParadiseSettingText } from "src/components/Paradise/ParadiseSettings/ParadiseSettingText"
import { TSettingContainerOnSaveReturnType } from "src/components/Settings/Setting/SettingContainer"
import { usePatchApiClient } from "src/data/apiClients/apiClientQueries"
import {
  IApiClient,
  IApiClientPatchBody,
} from "src/data/apiClients/apiClientTypes"
import { OwnerType } from "src/data/integrations/types/integrationTypes"
import { Routes } from "src/router/routes"
import ExpandDownIcon from "src/ui/icons/expand-down.svg"
import { InternalLink } from "src/ui/Link/InternalLink"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"
import { formatDate } from "src/utils/l10n"

export function ParadiseClientOverview({ client }: { client: IApiClient }) {
  const [showClientSecret, setShowClientSecret] = useState(false)
  const anchorRef = useRef(null)

  const patchApiClient = usePatchApiClient()

  async function updateClient(
    body: IApiClientPatchBody
  ): TSettingContainerOnSaveReturnType {
    try {
      await patchApiClient.mutateAsync({
        clientId: client.client_id,
        body,
      })

      return {
        isSuccess: true,
      }
    } catch (error) {
      return {
        isSuccess: false,
      }
    }
  }

  return (
    <ParadiseOverviewExpandable rawJson={client}>
      <ParadiseSettingText
        title="Name"
        value={client.name}
        onSave={(input) =>
          updateClient({
            name: input,
          })
        }
      />
      <ParadiseSettingText
        title="Description"
        value={client.description}
        onSave={(input) =>
          updateClient({
            description: input,
          })
        }
      />
      <ParadiseSettingText
        title="Logo URL"
        value={client.logo_url || ""}
        textFieldProps={{
          helperText:
            "Should be a square image, preferable hosted in some of our own S3 buckets so we know it won't go away. This will only be shown in the consent page when linking to this application",
        }}
        onSave={(input) =>
          updateClient({
            logo_url: input,
          })
        }
      />
      <ParadiseSettingStatic
        title="Owner"
        value={
          client.owner.type === OwnerType.ORGANIZATION ? (
            <InternalLink
              to={Routes.ParadiseOrganization.location(client.owner.id)}
            >
              {client.owner.id}
            </InternalLink>
          ) : (
            <InternalLink to={Routes.ParadiseUser.location(client.owner.id)}>
              {client.owner.id}
            </InternalLink>
          )
        }
      />
      <ParadiseSettingStatic title="Owner type" value={client.owner.type} />
      <ParadiseSettingStatic
        title="Created at"
        value={formatDate({ date: client.created_at })}
      />
      <ParadiseSettingStatic
        title="Client secret"
        value={
          <>
            <SecretWrapper ref={anchorRef}>
              <MText variant="body">••••••••••••••</MText>
              <IconButton
                size="medium"
                onClick={() => {
                  setShowClientSecret(true)
                }}
              >
                <ExpandDownIcon height={10} />
              </IconButton>
            </SecretWrapper>
            <SecretPopover
              open={showClientSecret}
              anchorEl={anchorRef.current}
              clientId={client.client_id}
              onClose={() => setShowClientSecret(false)}
            />
          </>
        }
      />
    </ParadiseOverviewExpandable>
  )
}

const SecretWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing.M};
`
